<template>
  <div class="relative">
    <div
      class="selector relative meta-info-text w-full flex uppercase cursor-pointer"
      :class="{
        'text-grey800': hasSelected || !placeholder,
        'text-grey600': !hasSelected && placeholder,
        'h-56': !small,
        'h-40 selector--small': small,
      }"
    >
      <select
        class="meta-info-text uppercase cursor-pointer"
        :class="{ 'text-error': error }"
        selected="selected"
        @change="onChange"
      >
        <option v-if="placeholder" value="" disabled selected>
          {{ placeholder }}
        </option>
        <option
          v-for="item in filteredOptions"
          :key="item.value"
          class="first:pt-0 pt-8 select-none cursor-pointer uppercase text-grey800"
          :value="item.value"
          :selected="item.isSelected && !placeholder ? 'selected' : undefined"
        >
          {{ item.label }}
        </option>
      </select>
    </div>
  </div>
</template>
<script lang="ts">
import { type PropType } from 'vue';
declare interface Item {
  value: string;
  label: string;
  isSelected?: boolean;
}
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array as PropType<
        { value: string; label: string; isSelected?: boolean }[]
      >,
      required: true,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    initValue: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      hasSelected: false,
      filteredOptions: [] as Item[],
    };
  },
  watch: {
    initValue() {
      this.setPreSelected();
    },
  },
  mounted() {
    if (this.initValue) {
      this.setPreSelected();
    } else {
      this.filteredOptions = this.items;
    }
  },
  methods: {
    onChange(e: any) {
      const value = e.target.value as String;
      this.hasSelected = true;
      this.$emit('changed', value);
    },
    setPreSelected() {
      this.items.forEach((item) => {
        if (item.value === this.initValue) {
          item.isSelected = true;
        }
      });

      this.filteredOptions = this.items;
    }
  },
});
</script>
<style>
.selection-open-enter-active,
.selection-open-leave-active {
  transition: all 0.1s;
}
.selection-open-enter,
.selection-open-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

.selector::after {
  position: absolute;
  right: 0.8rem;
  top: 1.1rem;
  content: url('/assets/icons/chevron-down-regular.svg');
  pointer-events: none;
  height: 14px;
  width: 14px;
}
.selector--small::after {
  top: 0.6rem;
}

select {
  background: transparent;
  appearance: none;
  border: 0;
  width: 100%;
  height: 100%;
  padding-left: 16px;
}

select::-ms-expand {
  display: none;
}
</style>
